export const BLOCK_ICON = 'fa fa-simplybuilt';

export const blockParams = {
  footer_with_app_link: {
    params: [],
  },
  thin_block_with_link: {
    params: ['image', 'bg_color', 'link'],
  },
  important_item: {
    params: ['bg_color', 'item'],
  },
  items_with_bg_and_image: {
    params: ['image', 'bg_color', 'items'],
  },
  in_app_stories: {
    params: ['feed_name'],
  },
  grid_items: {
    params: ['items'],
  },
  items: {
    params: ['items'],
  },
  banners_with_button_links: {
    params: ['references'],
  },
  popular_items: {
    params: ['item_types'],
  },
  latest_items: {
    params: ['item_types'],
  },
  popular_requests_items: {
    params: [],
  },
  sets_items: {
    params: ['sets_items'],
  },
  items_types: {
    params: [],
  },
};

export const itemTypesOptions: {
  label: string;
  key: string;
  value: number;
}[] = [
  {
    label: 'Книги',
    key: 'book',
    value: 1,
  },{
    label: 'Аудио',
    key: 'audiobook',
    value: 2,
  },{
    label: 'Курс',
    key: 'course',
    value: 3,
  },{
    label: 'Видео',
    key: 'video',
    value: 4,
  },{
    label: 'Документ',
    key: 'document',
    value: 5,
  },{
    label: 'Саммари',
    key: 'summary',
    value: 6,
  },{
    label: 'Аудиосаммари',
    key: 'audiosummary',
    value: 7,
  },{
    label: 'Статья',
    key: 'article',
    value: 8,
  },{
    label: 'Мини-курс',
    key: 'videosummary',
    value: 9,
  },{
    label: 'Тест',
    key: 'test',
    value: 10,
  },{
    label: 'Мероприятия',
    key: 'event',
    value: 11,
  },
];
