import React from 'react';
import {
  graphql,
  OptionProps,
} from 'react-apollo';
import {
  client,
  gql,
} from 'features/graphql';
import {
  IListQueryVariables,
  ISelectOption,
  ISharedSelectProps,
} from 'features/types';
import {
  mapFullEntityToOptions,
  ReactSelectWithId,
} from 'features/ui/ReactSelect';
import { IBlock } from 'features/blocks/types';
import { ISet } from 'features/sets/types';

const QUERY = gql`
    query getSet($id: [ID]) {
    SetQuery(id: $id) {
      items {
        id
        name
      }
    }
  }
`;

interface ISetsSelectProps extends ISharedSelectProps {
  data: Set[];
}

interface ISetsSelectState {
  options: ISelectOption[];
}

class SetsSelect extends React.PureComponent<ISetsSelectProps, ISetsSelectState> {
  static getDerivedStateFromProps(props: any) {
    return {
      options: mapFullEntityToOptions<IBlock>(props.data, 'name'),
    };
  }

  state = {
    options: [],
  };

  loadOptions = (value: string) => client.query<ISet>({
    query: QUERY,
    variables: {
      page: 1,
      perPage: 50,
    },
  }).then((res) => {
    if (res.data && res.data.SetQuery) {
      let sets = res.data.SetQuery.items;
      return sets.map((set: ISet) => ({
        value: +set.id,
        label: String(set.name),
      })).sort((a: ISelectOption<number>, b: ISelectOption<number>) => (a.value - b.value));
    }
    return [];
  });

  render() {
    const {
      id,
      name,
      value,
      onBlur,
      onChange,
      isMulti,
    } = this.props;
    const { options } = this.state;
    return (
      <ReactSelectWithId
        id={id}
        name={name}
        options={options}
        isMulti={!!isMulti}
        async
        loadOptions={this.loadOptions}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        minSearchLength={1}
      />
    );
  }
}

const mapResultsToProps = (props: OptionProps<ISharedSelectProps, ISet, IListQueryVariables>) => {
  const { data, ownProps } = props;
  return {
    data: data && data.SetQuery ? data.SetQuery.items : [],
    ...ownProps,
  };
};

export default graphql<ISharedSelectProps, ISet, IListQueryVariables, any>(QUERY, {
  props: mapResultsToProps,
  options: () => ({
    variables: {
      page: 1,
      perPage: 50,
    },
  }),
})(SetsSelect);
