import { filterInputHandler } from 'features/common';

export default () => [
  {
    Header: 'Подборка',
    accessor: 'name',
    filterable: true,
    minWidth: 100,
    Filter: filterInputHandler,
  },
];
