import { WithTranslation, withTranslation } from 'react-i18next';
import React from 'react';
import { SUBSCRIPTION_ICON } from 'features/subscriptions/consts';
import { makeSetUrl, makeSetUrlTo } from 'features/sets/lib/url';
import {
  InfoRow,
  InfoRowImage,
  InfoRowLinkList,
  InfoRowText,
} from 'features/ui/InfoRow';
import { createView } from 'features/common';
import { ISubscription } from 'features/subscriptions/types';
import getEntity from 'features/sets/queries/getSet.gql';
import { ISet } from 'features/sets/types';
import LinkBlock from 'features/ui/LinkBlock';
import { IItem } from 'features/items/types';
import LinkToItem from 'features/items/lib/LinkToItem';
import { get } from 'lodash';
import { makeLinkListValue } from 'lib/makeLinkListValue';
import { makeOfferUrl } from 'features/offers/lib/url';
import { makeCreatorUrl } from 'features/creators/lib/url';

const View = createView<ISubscription>(getEntity, 'SetQuery');

class PageView extends React.PureComponent<WithTranslation> {
  renderChildren = (set: ISet) => {
    const { t } = this.props;
    const offersLinkListValue = makeLinkListValue(set.offers, 'name', makeOfferUrl, 'view');
    const creatorsLinkListValue = makeLinkListValue(set.creators, 'full_name', makeCreatorUrl, 'view');
    return (
      <React.Fragment>
        <LinkBlock editLink={makeSetUrlTo(set, 'edit')} />
        <InfoRowText label="ID" value={set.id} />
        <InfoRowText label={t('name')} value={set.name} />
        <InfoRowText label={t('description')} value={set.description} />
        <InfoRow label={t('items')}>
          {set.items
            .sort((a, b) => a.sort - b.sort)
            .map((item: IItem) => (
            <>
              <LinkToItem value={item} />
              <InfoRowText label={t('description')} value={item.description} />
            </>
            ))}
        </InfoRow>
        <InfoRowImage
          label={t('image')}
          value={get(set, 'images[0].path', null)}
          width={1440 / 4/* from original size to preview */}
          height={768 / 4}
        />
        <InfoRowLinkList
          label={t('creators')}
          value={creatorsLinkListValue}
          byLine={true}
          max={10}
        />
        <InfoRowLinkList
          label={t('offers:offers')}
          value={offersLinkListValue}
          byLine={true}
          max={10}
        />
      </React.Fragment>
    );
  }

  render() {
    const { t } = this.props;
    return (
      <View
        titleField="name"
        icon={SUBSCRIPTION_ICON}
        crumbs={[{ link: makeSetUrl(), name: t('set') }]}
        children={this.renderChildren}
      />
    );
  }
}
export default withTranslation('sets')(PageView);
