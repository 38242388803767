import React from 'react';
import {
  Formik,
  FormikActions,
  FormikProps,
} from 'formik';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { crudMutate } from 'features/common/helpers';
import {
  imageFileValidator,
  stringValidator,
  weightValidator,
} from 'lib/validators';
import filterChangedValues from 'lib/filterChangedValues';
import field from 'lib/field';
import BaseBannerForm from './BaseBannerForm';
import { makeBannerUrl } from '../url';
import createBannerMutation from '../../queries/createBanner.gql';
import editBannerMutation from '../../queries/editBanner.gql';
import {
  IBanner,
  IBannerFormValues,
} from '../../types';

import * as Yup from 'yup';

interface IBadgeFormProps extends WithTranslation {
  banner?: IBanner;
  isCreateForm?: boolean;
}

class BannerForm extends React.Component<IBadgeFormProps> {
  getScheme() {
    const {
      isCreateForm,
      t,
    } = this.props;
    const required = true;
    const validators: any = {
      name: stringValidator({ required, field: t('name') }),
      weight: weightValidator({ required, field: t('weight') }),
      resource_link: stringValidator({ required, field: t('resource_link') }),
      image: imageFileValidator({
        field: t('image'),
        required: !!isCreateForm,
      }),
    };
    return Yup.object().shape(validators);
  }

  getChangedValues(values: IBannerFormValues) {
    const formData = this.buildFormData();
    return filterChangedValues(formData, values);
  }

  onSubmit = (
    values: IBannerFormValues,
    formActions: FormikActions<IBannerFormValues>,
  ) => {
    let banner = { ...values };
    let id;
    if (this.props.banner) {
      banner = this.getChangedValues(values);
      id = this.props.banner.id;
    }
    crudMutate({
      id,
      formActions,
      variables: id ? { id, banner } : { banner },
      mutation: id ? editBannerMutation : createBannerMutation,
      redirect: makeBannerUrl(),
      check: !!Object.keys(banner).length,
    });
  };

  buildFormData(): IBannerFormValues {
    const { banner } = this.props;
    return {
      name: field(banner, 'name', ''),
      is_active: field(banner, 'is_active', false),
      resource_type: field(banner, 'resource_type', 'category'),
      resource_link: field(banner, 'resource_link', ''),
      weight: field(banner, 'weight', 0),
      image: field(banner, 'images[0].path', null),
      offers: banner ? banner.offers.map(o => o.id) : [],
      started_at: field(banner, 'started_at', null),
      finished_at: field(banner, 'finished_at', null),
      format: field(banner, 'format', 'standard'),
    };
  }

  render() {
    const { banner } = this.props;
    return (
      <Formik
        initialValues={this.buildFormData()}
        onSubmit={this.onSubmit}
        validationSchema={this.getScheme()}
      >
        {(props: FormikProps<IBannerFormValues>) => <BaseBannerForm {...props} banner={banner} />}
      </Formik>
    );
  }
}

export default withTranslation('banners')(BannerForm);
