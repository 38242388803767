import React from 'react';
import { set } from 'lodash';
import { Column } from 'react-table';
import makeColumns from './columns';
import CardPage from 'features/ui/Page/CardPage';
import { FEATURE_ICON } from 'features/featureToggle/consts';
import IndexTableBase, { IControlFields } from 'features/common/IndexTableBase';
import getSetsQuery from 'features/sets/queries/getSets.gql';
import { makeSetUrl } from 'features/sets/lib/url';
import deleteSetMutation from '../queries/deleteSet.gql';
import { ISet } from 'features/sets/types';
import { withTranslation, WithTranslation } from 'react-i18next';

interface IPageSetsProps extends ISet, WithTranslation {}

class PageSets extends React.PureComponent<IPageSetsProps> {
  private readonly _columns: Column[];
  constructor(props: IPageSetsProps) {
    super(props);
    this._columns = makeColumns();
  }
  prepareRequestData = (variables: any, fields: IControlFields) => {
    if (fields.id) {
      variables.id = [fields.id];
    }
    if (fields.name) {
      set(variables, 'filterByFields.name', {
        operator: 'like',
        value: fields.name,
      });
    }
  };

  render() {
    const { t } = this.props;
    return (
      <CardPage
        icon={FEATURE_ICON}
        buttons={[{ link: '/set/create', name: t('add_new_set') }]}
        title={t('sets')}
      >
        <IndexTableBase
          query={getSetsQuery}
          queryResultName="SetQuery"
          columns={this._columns}
          makeCrudUrl={makeSetUrl}
          filterFields={['id', 'name']}
          deleteMutation={deleteSetMutation}
          prepareRequestData={this.prepareRequestData}
          isFilterableById={true}
          showAutoEditIconColumn={true}
          showViewIconColumn={true}
        />
      </CardPage>
    );
  }
}

export default withTranslation('sets')(PageSets);
